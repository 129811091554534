// Define your base URL and API endpoints here
// local
//export const BASE_URL = "http://192.168.1.2:4001/api";
export const BASE_URL_QR = "https://lmsapi.eduskillsfoundation.org";
// export const BASE_URL = "http://ec2-15-206-231-196.ap-south-1.compute.amazonaws.com/lmsapi/api";
// server
export const BASE_URL = "https://lmsapi.eduskillsfoundation.org/api";
export const API_URLS = {
  // auth
  LOGIN: "/auth/login",
  LOGOUT: "/auth/logout",
  GET_ROLE: "/auth/roleDropdown",
  ROLE: "/auth/role",
  // course
  COURSE: "/auth/course",
  CategoryDropdown: "/auth/getCategoryDropdown",
  Category: "/auth/category",
  Module: "/auth/module",
  Certificate: "/auth/certificate",
  getProfile: "/auth/getProfile",
  getCourseForUser: "/auth/getCourseForUser",
  getModuleForUser: "/auth/getModuleForUser",
  getModule: "/auth/getModule",
  Progress: "/auth/progress",
  Marks: "/auth/marks",
  // user
  createUser: "/auth/createUser",
  getGenCertificate: "/auth/getGenCertificate",
};
