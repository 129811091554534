import React from "react";
import { Modal, Box, Typography, Button, IconButton, Icon } from "@mui/material";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";

const DeleteConfirmationModal = ({ open, onClose, onDelete }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Confirm Deletion
        </Typography>
        <Typography variant="body1" gutterBottom>
          Are you sure you want to delete this module/course?
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <MDButton onClick={onClose} color="secondary" variant="contained" sx={{ mr: 2 }}>
            Cancel
          </MDButton>
          <MDButton onClick={onDelete} color="error" variant="contained">
            Delete
          </MDButton>
        </Box>
      </Box>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired, // Determines if the modal is open or closed
  onClose: PropTypes.func.isRequired, // Callback function to close the modal
  onDelete: PropTypes.func.isRequired, // Callback function to handle deletion
};

export default DeleteConfirmationModal;
