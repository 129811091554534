import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import { UserService } from "services/service";
import * as pdfjs from "pdfjs-dist";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfViewerModule = ({
  pdfSrc,
  title,
  description,
  setCurrentIndex,
  isCompleted,
  courseCode,
  moduleCode,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [timer, setTimer] = useState(20);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [completedPages, setCompletedPages] = useState([]);
  const [scale, setScale] = useState(1);
  const canvasRef = useRef(null);
  const pdfRef = useRef(null);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const storageKey = `${courseCode}_${moduleCode}_completedPages`;

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        const loadingTask = pdfjs.getDocument(pdfSrc);
        pdfRef.current = await loadingTask.promise;
        setTotalPages(pdfRef.current.numPages);
        renderPage(pdfRef.current, currentPage, scale);
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    };

    fetchPDF();
  }, [pdfSrc, currentPage, scale]);

  useEffect(() => {
    const storedCompletedPages = JSON.parse(localStorage.getItem(storageKey) || "[]");
    setCompletedPages(storedCompletedPages);
  }, [storageKey]);

  useEffect(() => {
    if (completedPages.length > 0) {
      localStorage.setItem(storageKey, JSON.stringify(completedPages));
    }
  }, [completedPages, storageKey]);

  useEffect(() => {
    let timerInterval;
    if (isTimerRunning) {
      timerInterval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(timerInterval);
            setIsTimerRunning(false);
            setCompletedPages((prev) => {
              const newCompletedPages = [...prev, currentPage];
              localStorage.setItem(storageKey, JSON.stringify(newCompletedPages));
              return newCompletedPages;
            });
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timerInterval);
  }, [isTimerRunning, currentPage, storageKey]);

  useEffect(() => {
    if (currentPage <= totalPages && !completedPages.includes(currentPage)) {
      setTimer(10);
      setIsTimerRunning(true);
    } else {
      setTimer(0);
      setIsTimerRunning(false);
    }
  }, [currentPage, totalPages, completedPages]);

  const renderPage = async (pdf, pageNumber, scale) => {
    const page = await pdf.getPage(pageNumber);
    const viewport = page.getViewport({ scale });
    const canvas = canvasRef.current;

    if (canvas) {
      const context = canvas.getContext("2d");
      const devicePixelRatio = window.devicePixelRatio || 1;

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport,
      };

      if (canvas.__pdfRendering) {
        canvas.__pdfRendering.cancel();
      }

      const renderTask = page.render(renderContext);
      canvas.__pdfRendering = renderTask;

      renderTask.promise.then(
        () => {
          canvas.__pdfRendering = null;
        },
        (error) => {
          if (error instanceof pdfjs.RenderingCancelledException) {
            console.log("Rendering cancelled: ", error.message);
          } else {
            console.error("Rendering error: ", error);
          }
          canvas.__pdfRendering = null;
        }
      );
    } else {
      console.error("Canvas element not found.");
    }
  };

  const handleNextPage = async () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    } else if (isCompleted) {
      setCurrentIndex((prev) => prev + 1);
    } else if (moduleCode && courseCode) {
      try {
        setLoading(true);
        await UserService.progress({
          courseCode,
          moduleCode,
          isDocumentComplete: 1,
          isVideoComplete: 1,
          isQuestionComplete: 0,
          isFinalQuestionComplete: 0,
          record_status: 1,
          opType: "UPDATE_PROGRESS",
        });
        setCurrentIndex((prev) => prev + 1);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    return () => {
      if (canvasRef.current && canvasRef.current.__pdfRendering) {
        canvasRef.current.__pdfRendering.cancel();
      }
    };
  }, []);

  return (
    <MDBox py={0} px={3}>
      <Typography variant="h4" fontSize={isSmallScreen ? 16 : 18} gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" mt={1} fontSize={14} paragraph>
        {description}
      </Typography>
      <MDBox
        mt={0.6}
        style={{
          border: "1px solid #ccc",
          borderRadius: "8px",
          overflow: "hidden",
          textAlign: "center",
        }}
      >
        <canvas ref={canvasRef} style={{ margin: "0 auto", display: "block", maxWidth: "100%" }} />
      </MDBox>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "8px",
          marginBottom: "16px",
        }}
      >
        {Array.from({ length: totalPages }, (_, index) => (
          <Box
            key={index}
            sx={{
              width: `${100 / totalPages}%`,
              height: "105%",
              backgroundColor: currentPage > index ? "#4caf50" : "#ccc",
              border: "1px solid white",
            }}
          />
        ))}
      </Box>

      <MDBox mt={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Typography variant="body1" fontSize={14}>
            Page {currentPage} of {totalPages}
          </Typography> */}
        </Box>

        <Box
          mt={2}
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: isSmallScreen ? 2 : 0,
            }}
          >
            {isTimerRunning && (
              <>
                <CircularProgress
                  variant="determinate"
                  color="info"
                  value={(timer / 10) * 100}
                  size={24}
                  sx={{ marginRight: 1 }}
                />
                <Typography variant="body2" sx={{ marginRight: 2 }}>
                  {timer}s
                </Typography>
              </>
            )}
            <MDButton
              size="small"
              color="info"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              style={{ marginRight: "10px" }}
            >
              Previous Page
            </MDButton>
            <MDButton
              size="small"
              color="info"
              onClick={handleNextPage}
              disabled={loading || (isTimerRunning && !completedPages.includes(currentPage))}
            >
              {loading ? "Loading..." : currentPage < totalPages ? "Next Page" : "Complete"}
            </MDButton>
          </Box>
        </Box>
      </MDBox>
    </MDBox>
  );
};

PdfViewerModule.propTypes = {
  pdfSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isCompleted: PropTypes.any,
  courseCode: PropTypes.number.isRequired,
  moduleCode: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
};

export default PdfViewerModule;
