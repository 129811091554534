import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import ErrorImg from "../assets/images/error.svg"; // Import the error SVG image

const NetworkErrorModal = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="network-error-dialog-title"
      aria-describedby="network-error-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "#f5f5f5",
          padding: "20px",
          borderRadius: "10px",
        },
      }}
    >
      <DialogContent style={{ textAlign: "center", maxWidth: 400 }}>
        <img src={ErrorImg} alt="Network Error" style={{ width: "200px", marginBottom: "20px" }} />
        <DialogContentText
          id="network-error-dialog-description"
          style={{ color: "#333", marginBottom: "20px" }}
        >
          A network error occurred. Please check your internet connection and try again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => window.location.reload()}
          variant="contained"
          style={{
            backgroundColor: "#d32f2f",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "5px",
          }}
        >
          Refresh
        </Button>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{
            borderColor: "#d32f2f",
            color: "#d32f2f",
            padding: "10px 20px",
            borderRadius: "5px",
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NetworkErrorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NetworkErrorModal;
