import { Card, Icon, IconButton, Paper, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useState } from "react";
import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";

const PhotoCard = ({ title, photoUrl, setPhotoUrl }) => {
  const [editing, setEditing] = useState(false);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleSave = () => {
    setEditing(false);
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhotoUrl(file); // Set the File object directly
      setEditing(false);
    }
  };

  const isFile = (url) => {
    return url instanceof File;
  };

  return (
    <Paper elevation={0} sx={{ borderRadius: 1, px: 2, py: 1, my: 2 }}>
      <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: 14 }} fontWeight={600}>
          {title}
        </Typography>
        {editing ? (
          <MDButton size="small" onClick={handleCancel}>
            Cancel
          </MDButton>
        ) : (
          <MDButton
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
            size="small"
            onClick={handleEdit}
          >
            <Icon>{photoUrl ? "edit" : "add_circle_outline"}</Icon>
            {photoUrl ? "Change" : "Add Photo"}
          </MDButton>
        )}
      </MDBox>
      {editing ? (
        <>
          <input
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
            style={{ display: "block", marginBottom: "1rem" }}
          />
        </>
      ) : (
        photoUrl && (
          <img
            src={isFile(photoUrl) ? URL.createObjectURL(photoUrl) : photoUrl}
            alt="Course"
            style={{ width: "100%", marginTop: "1rem" }}
          />
        )
      )}
    </Paper>
  );
};

PhotoCard.propTypes = {
  title: PropTypes.string.isRequired,
  photoUrl: PropTypes.string.isRequired,
  setPhotoUrl: PropTypes.func.isRequired,
};

export default PhotoCard;
