import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Paper, CircularProgress, Icon } from "@mui/material";
import { green } from "@mui/material/colors";
import PropTypes from "prop-types";
import { UserService } from "services/service";

const QuizComponent = ({
  questions,
  title,
  setCurrentIndex,
  courseCode,
  moduleCode,
  isCompleted,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    const savedIndex = localStorage.getItem("currentQuestionIndex");
    return savedIndex !== null ? JSON.parse(savedIndex) : 0;
  });

  const [userAnswers, setUserAnswers] = useState(() => {
    const savedAnswers = sessionStorage.getItem("userAnswers");
    return savedAnswers ? JSON.parse(savedAnswers) : {};
  });

  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = localStorage.getItem("timeLeft");
    return savedTime !== null ? JSON.parse(savedTime) : 60;
  });

  const [quizCompleted, setQuizCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAssessment, setShowAssessment] = useState(false);

  const handleOptionSelect = (questionId, selectedOption) => {
    setUserAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers, [questionId]: selectedOption };
      sessionStorage.setItem("userAnswers", JSON.stringify(newAnswers));
      return newAnswers;
    });

    setTimeout(() => {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex((prevIndex) => {
          const newIndex = prevIndex + 1;
          localStorage.setItem("currentQuestionIndex", JSON.stringify(newIndex));
          return newIndex;
        });
        setTimeLeft(60);
        localStorage.setItem("timeLeft", JSON.stringify(60));
      } else {
        setQuizCompleted(true);
        calculateScore();
        localStorage.removeItem("timeLeft");
        localStorage.removeItem("currentQuestionIndex");
        sessionStorage.removeItem("userAnswers");
      }
    }, 500);
  };

  const calculateScore = () => {
    let score = 0;
    questions.forEach((question) => {
      if (userAnswers[question.id] === question.answer) {
        score++;
      }
    });
    setScore(score);
  };

  useEffect(() => {
    let timer = null;
    if (currentQuestionIndex < questions.length && timeLeft > 0) {
      timer = setTimeout(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem("timeLeft", JSON.stringify(newTime));
          return newTime;
        });
      }, 1000);
    } else {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex((prevIndex) => {
          const newIndex = prevIndex + 1;
          localStorage.setItem("currentQuestionIndex", JSON.stringify(newIndex));
          return newIndex;
        });
        setTimeLeft(60);
        localStorage.setItem("timeLeft", JSON.stringify(60));
      } else {
        setQuizCompleted(true);
        calculateScore();
        localStorage.removeItem("timeLeft");
        localStorage.removeItem("currentQuestionIndex");
        sessionStorage.removeItem("userAnswers");
      }
    }
    return () => clearTimeout(timer);
  }, [timeLeft, currentQuestionIndex]);

  const handleMarkAsComplete = async () => {
    if (isCompleted) {
      setCurrentIndex((prev) => prev + 1);
    } else if (moduleCode && courseCode) {
      try {
        setLoading(true);
        let payload;
        if (courseCode == moduleCode) {
          payload = {
            courseCode,
            name: "question",
            isDocumentComplete: 1,
            isVideoComplete: 0,
            isQuestionComplete: 0,
            isFinalQuestionComplete: 1,
            record_status: 1,
            opType: "UPDATE_PROGRESS",
          };
        } else {
          payload = {
            courseCode,
            moduleCode,
            isDocumentComplete: 1,
            isVideoComplete: 1,
            isQuestionComplete: 1,
            isFinalQuestionComplete: null,
            recordStatus: 1,
            opType: "UPDATE_PROGRESS",
          };
        }
        const res = await UserService.progress(payload);
        console.log(courseCode);
        console.log(moduleCode);
        setCurrentIndex((prev) => prev + 1);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  const handleScore = async () => {
    if (!isCompleted && moduleCode && courseCode) {
      try {
        setLoading(true);
        const res = await UserService.score({
          courseCode,
          moduleCode,
          examType: courseCode === moduleCode ? 0 : 1, // 0 - final exam, 1 - submodules
          marks: score,
          recordStatus: 1,
        });
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  //   useEffect(() => {
  //     if (quizCompleted) {
  //       handleMarkAsComplete();
  //       handleScore();
  //     }
  //   }, [quizCompleted]);
  useEffect(() => {
    if (quizCompleted) {
      const timer = setTimeout(() => {
        handleMarkAsComplete();
        handleScore();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [quizCompleted]);

  const startAssessment = () => {
    setShowAssessment(true);
    localStorage.removeItem("timeLeft");
    localStorage.removeItem("currentQuestionIndex");
    sessionStorage.removeItem("userAnswers");
    setCurrentQuestionIndex(0);
    setTimeLeft(60);
    setUserAnswers({});
  };

  if (!showAssessment) {
    return (
      <Box sx={{ textAlign: "center", mt: 4, minHeight: "420px" }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {!title ? "Start Your Final Assessment" : "Start Your Module Assessment"}
          </Typography>
          <Typography color={"darkblue"} variant="h3" sx={{ mb: 2 }}>
            <Icon sx={{ mb: -0.5 }}>assessment</Icon> {title}
          </Typography>
          <Button
            variant="contained"
            onClick={startAssessment}
            sx={{
              backgroundColor: green[500],
              color: "#fff",
              "&:hover": { backgroundColor: green[700] },
            }}
          >
            Start Assessment
          </Button>
        </Paper>
      </Box>
    );
  }

  if (quizCompleted) {
    return (
      <Box sx={{ textAlign: "center", mt: 4, minHeight: "420px" }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Your Assessment Score
          </Typography>
          <Typography color={"darkblue"} variant="h3" sx={{ mb: 2 }}>
            {score} / {questions.length}
          </Typography>
        </Paper>
      </Box>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <Box
      sx={{ textAlign: "center", my: 4, minHeight: 400, display: "flex", justifyContent: "center" }}
    >
      <Paper elevation={0} sx={{ p: 4, maxWidth: 700 }}>
        <Typography
          variant="h5"
          color="GrayText"
          sx={{
            mb: 2,
          }}
        >
          Question {currentQuestionIndex + 1}
        </Typography>
        <Typography variant="h6" sx={{ mb: 4 }}>
          {currentQuestion.question}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
          {currentQuestion.options.map((option) => (
            <Button
              key={option}
              variant="contained"
              sx={{
                m: 1,
                minWidth: 200,
                backgroundColor:
                  userAnswers[currentQuestion.id] === option ? green[500] : "inherit",
                color: userAnswers[currentQuestion.id] === option ? "#fff" : "inherit",
                "&:hover": {
                  backgroundColor:
                    userAnswers[currentQuestion.id] === option ? green[700] : "#e0e0e0",
                },
              }}
              onClick={() => handleOptionSelect(currentQuestion.id, option)}
              disabled={userAnswers[currentQuestion.id] !== undefined}
            >
              {option}
            </Button>
          ))}
        </Box>
        <Box sx={{ mt: 3 }}>
          <CircularProgress
            variant="determinate"
            value={(timeLeft / 60) * 100}
            color={
              timeLeft < 15
                ? "error"
                : timeLeft < 25
                ? "warning"
                : timeLeft < 45
                ? "info"
                : "success"
            }
          />
        </Box>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Time left: {timeLeft} seconds
        </Typography>
      </Paper>
    </Box>
  );
};

QuizComponent.propTypes = {
  questions: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  courseCode: PropTypes.string.isRequired,
  moduleCode: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
};

export default QuizComponent;
