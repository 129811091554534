import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { UserService } from "services/service";
import CourseCard from "./CourseCard";

const Certificates = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  // use-code
  // usename
  // ceri code
  // corse code corse name certifuica f\photeo date

  const getCourseForUser = async () => {
    try {
      setIsLoading(true);
      const res = await UserService.getCourseForUser();
      setCourses(res.data?.responseData?.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCourseForUser();
  }, []);

  // Filter courses with progress === 100
  const completedCourses = courses.filter((course) => course.progress === 100);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={1} mb={2} sx={{ minHeight: "75vh" }}>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={300}
            width="100%"
          >
            <CircularProgress color="info" size={70} />
          </Box>
        ) : (
          <>
            {completedCourses.length > 0 ? (
              completedCourses.map((course, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <CourseCard course={course} />
                </Grid>
              ))
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={300}
                width="100%"
              >
                <Typography variant="h6" color="text.secondary">
                  No Certificate Generated Yet
                </Typography>
              </Box>
            )}
          </>
        )}
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default Certificates;

// import React, { useState } from "react";
// import { Document, Page, pdfjs } from "react-pdf";
// import { Box, CircularProgress, useMediaQuery, IconButton, Icon, styled } from "@mui/material";
// import pdfFile from "./Module_1_REACT_JS.pdf";
// import Footer from "examples/Footer";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// // Set the worker source for pdfjs
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// // Custom styled component for the navigation buttons
// const NavIconButton = styled(IconButton)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   transform: "translateY(-50%)",
//   backgroundColor: theme.palette.action.disabledBackground,
//   color: theme.palette.primary.contrastText,
//   "&:hover": {
//     backgroundColor: theme.palette.action.active,
//   },
// }));

// const Certificates = () => {
//   const [numPages, setNumPages] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1); // State to manage current page

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//     setIsLoading(false);
//   };

//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

//   const goToPreviousPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const goToNextPage = () => {
//     if (currentPage < numPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <Box sx={{ position: "relative", padding: 2 }}>
//         {isLoading ? (
//           <Box display="flex" justifyContent="center" alignItems="center" minHeight={300}>
//             <CircularProgress color="info" size={70} />
//           </Box>
//         ) : (
//           <Box
//             sx={{
//               position: "relative",
//               maxHeight: isMobile ? 300 : 500, // Adjust maxHeight based on screen size
//               overflow: "hidden",
//               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//               px: 1,
//             }}
//           >
//             <NavIconButton
//               sx={{ left: 10, zIndex: 900 }} // Adjust left position to ensure visibility
//               onClick={goToPreviousPage}
//               disabled={currentPage === 1}
//               aria-label="previous-page"
//             >
//               <Icon>arrow_back_ios</Icon>
//             </NavIconButton>
//             <Document
//               file={pdfFile}
//               onLoadSuccess={onDocumentLoadSuccess}
//               loading={
//                 <Box display="flex" justifyContent="center" alignItems="center" minHeight={300}>
//                   <CircularProgress color="info" size={70} />
//                 </Box>
//               }
//             >
//               <Page pageNumber={currentPage} height={isMobile ? 300 : 500} />
//             </Document>
//             <NavIconButton
//               sx={{ right: 10 }} // Adjust right position to ensure visibility
//               onClick={goToNextPage}
//               disabled={currentPage === numPages}
//               aria-label="next-page"
//             >
//               <Icon>arrow_forward_ios</Icon>
//             </NavIconButton>
//           </Box>
//         )}
//       </Box>
//       <Footer />
//     </DashboardLayout>
//   );
// };

// export default Certificates;
