// LinearProgressWithLabel.js
import React from "react";
import PropTypes from "prop-types";
import { Box, LinearProgress, Typography } from "@mui/material";

const LinearProgressWithLabel = ({ value, darkMode }) => {
  // console.log(darkMode);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: `${value}%`, mr: 1 }}>
        <LinearProgress
          variant="determinate"
          color={value < 20 ? "error" : value < 40 ? "warning" : value < 80 ? "info" : "success"}
          value={value}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" fontWeight={600} fontSize={10} color={"gray"}>{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired, // Add PropTypes validation
  darkMode: PropTypes.bool,
};

export default LinearProgressWithLabel;
