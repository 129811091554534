import React, { useEffect, useState } from "react";
import { CssBaseline, Box, Container, CircularProgress } from "@mui/material";
import CourseVideoModule from "./courseVideoModule";
import PdfViewerModule from "./PdfViewerModule";
import QuizComponent from "./QuizComponent";
import { transformModulesToRoutes } from "routes";
import Sidenav from "examples/Sidenav";
import { useDispatch, useSelector } from "react-redux";
const logo = "https://eduskills.academy/images/home/academy%20logo.png";
import {
  setStudentModule,
  setRoutes,
  selectStudentModule,
  selectRoutes,
} from "store/Slices/appSlice";
import { UserService } from "services/service";
import { useParams } from "react-router-dom";
import brandWhite from "assets/images/logo-light.svg";
import brandDark from "assets/images/logo-dark.svg";
import { setTransparentSidenav } from "context";
import { setDarkMode } from "context";
import { setWhiteSidenav } from "context";

const CourseModules = () => {
  const [selectedModuleCode, setSelectedModuleCode] = useState(null);
  //   const [selectedModuleData, setSelectedModuleData] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [moduleForUser, setModuleForUser] = useState({});
  const [courseUser, setCourseUser] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);

  const studentModule = useSelector(selectStudentModule);
  const routes = useSelector(selectRoutes);
  //   console.log(selectedModuleData);
  const dispatch = useDispatch();

  const handleModuleSelect = (moduleCode) => {
    setModuleId(moduleCode.replace("module/", ""));
    setSelectedModuleCode(moduleCode);
    setCurrentIndex(0);
    // setSelectedModuleData(modulesDetail[moduleCode]);
  };

  const courseCode = sessionStorage.getItem("courseCode");
  // name:question
  const fetchModule = async () => {
    try {
      setLoading(true);
      let payload;
      if (courseCode === moduleId) {
        payload = { courseCode, name: "question" };
        setCurrentIndex(2);
      } else {
        payload = { courseCode, moduleCode: moduleId };
      }
      const res = await UserService.getModuleForUser(payload);
      console.log(res.data?.responseData?.data[0]);
      setModuleForUser(res.data?.responseData?.data[0]);
      if (!res.data?.responseData?.data[0]?.moduleVideo && currentIndex === 0) {
        setCurrentIndex(1);
      }

      setLoading(false);
    } catch (error) {
      console.error(error);

      setLoading(false);
    }
  };
  const fetchGetCourse = async () => {
    try {
      setLoading(true);
      const res = await UserService.getModule({
        courseCode,
      });

      setCourseUser(res.data?.responseData?.data);
      console.log(res);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (courseCode) {
      fetchGetCourse();
    }
  }, [courseCode, currentIndex === 3]);

  useEffect(() => {
    if (courseCode && moduleId) {
      fetchModule();
    }
  }, [courseCode, moduleId, currentIndex]);

  const renderModuleContent = () => {
    if (loading) {
      return (
        <Box
          minHeight="70vh"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="info" size={60} />
        </Box>
      );
    }
    if (!selectedModuleCode) {
      return (
        <Box
          minHeight="70vh"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          Select a module from the sidebar.
        </Box>
      );
    }

    const {
      moduleName,
      moduleDocument,
      moduleVideo,
      moduleDescription,
      selectedQuestions,
      isVideoCompleted,
      isPdfCompleted,
      isAssementCompleted,
    } = moduleForUser;

    if (moduleVideo && currentIndex === 0) {
      return (
        <div>
          <CourseVideoModule
            videoSrc={moduleVideo}
            title={moduleName}
            description={moduleDescription}
            setCurrentIndex={setCurrentIndex}
            isCompleted={isVideoCompleted}
            courseCode={courseCode}
            moduleCode={moduleId}
          />
        </div>
      );
    }

    if (moduleDocument && currentIndex === 1) {
      return (
        <div>
          <PdfViewerModule
            pdfSrc={moduleDocument}
            title={moduleName}
            description={moduleDescription}
            setCurrentIndex={setCurrentIndex}
            isCompleted={isPdfCompleted}
            courseCode={courseCode}
            moduleCode={moduleId}
          />
        </div>
      );
    }

    if (!isAssementCompleted && selectedQuestions?.length > 0 && currentIndex === 2) {
      return (
        <div>
          <QuizComponent
            title={moduleName || ""}
            questions={selectedQuestions || []}
            setCurrentIndex={setCurrentIndex}
            courseCode={courseCode}
            moduleCode={moduleId}
            isCompleted={isAssementCompleted}
          />
        </div>
      );
    }

    return (
      <Box minHeight="70vh" width="100%" display="flex" justifyContent="center" alignItems="center">
        {isAssementCompleted ? "The module is completed" : "Please Wait sometime..."}
      </Box>
    );
  };

  const route = transformModulesToRoutes(courseUser?.modules || []);
  // Function to update studentModule and routes
  useEffect(() => {
    const updateStudentModuleAndRoutes = async () => {
      await dispatch(setStudentModule(true));
      await dispatch(setRoutes(route));
    };
    updateStudentModuleAndRoutes();
  }, [dispatch, route.length]);

  useEffect(() => {
    const addProgress = async () => {
      try {
        setLoading(true);
        let payload;
        if (courseCode === moduleId) {
          payload = { courseCode, name: "question", opType: "ADD_PROGRESS" };
        } else {
          payload = { courseCode, moduleCode: moduleId, opType: "ADD_PROGRESS" };
        }
        const res = await UserService.progress(payload);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    if (courseCode && moduleId && moduleForUser?.isAssementCompleted === null) {
      addProgress();
    }
  }, [moduleId, moduleForUser?.isAssementCompleted === null]);

  return (
    <Box>
      <CssBaseline />
      <Sidenav
        color="info"
        brand={(setTransparentSidenav && !setDarkMode) || setWhiteSidenav ? logo : logo}
        brandName="EduSkills"
        routes={route}
        onModuleSelect={handleModuleSelect}
        progress={courseUser?.progress}
        courseName={courseUser?.courseName}
      />
      <Box>{renderModuleContent()}</Box>
    </Box>
  );
};

export default CourseModules;
