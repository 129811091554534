// CourseCard.js
import React from "react";
import PropTypes from "prop-types";
import { Card, CardMedia, CardContent, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import LinearProgressWithLabel from "./LinearProgressWithLabel"; // Import the component

const CourseCard = ({ course }) => {
  const { coursePhoto, courseName, description, countModule, progress, courseCode } = course;
  const truncate = (str, maxLength) => {
    return str?.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
  };
  const truncatedTitle = truncate(courseName, 18);
  const truncatedDescription = truncate(description, 55);

  return (
    <Link
      to={`/course`}
      onClick={() => sessionStorage.setItem("courseCode", courseCode)}
      style={{ textDecoration: "none" }}
    >
      <Card
        sx={{
          maxWidth: 345,
          margin: "1rem",
          borderRadius: "15px",
          boxShadow: 3,
          cursor: "pointer",
        }}
      >
        <CardMedia component="img" height="95px" image={coursePhoto} alt={courseName} />
        <CardContent>
          <Typography gutterBottom variant="h6" fontSize={14} component="div">
            {truncatedTitle}
          </Typography>
          <Typography
            variant="body2"
            lineHeight={1.3}
            fontSize={10}
            mt={-0.6}
            color="text.secondary"
          >
            {truncatedDescription}
          </Typography>
          <Box sx={{ mt: 0.2 }}>
            <Typography variant="h6" fontSize={12} color="text.secondary">
              Modules: {countModule}
            </Typography>
            <Box sx={{ mt: 0.2 }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};

CourseCard.propTypes = {
  course: PropTypes.shape({
    courseCode: PropTypes.number.isRequired,
    coursePhoto: PropTypes.string.isRequired,
    courseName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    countModule: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
  }).isRequired,
};

export default CourseCard;
