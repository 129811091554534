import axios from "axios";
import store from "../store/store";
import { clearTokens } from "../store/Slices/auth/authSlice";
import { BASE_URL } from "./configUrls";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: BASE_URL,
});

function handleInfoMessage(message) {
  toast.info(message, {
    autoClose: 2000,
    position: "top-center",
  });
}

api.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    config.headers.Authorization = accessToken; // Removed 'Bearer ' prefix
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 400 && !originalRequest._retry) {
      originalRequest._retry = true;

      store.dispatch(clearTokens());
      localStorage.clear();
      handleInfoMessage("Session expired. Redirecting to login page.");
      window.location.reload();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default api;
