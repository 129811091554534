import { Box, Card, CircularProgress, Icon, IconButton, Paper, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import React from "react";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";

const CourseModuleCard = ({
  title,
  onAddModule,
  isPublish,
  setIsChapterCreation,
  moduleList,
  moduleLoading,
  setCurrentModule,
  courseCode,
}) => {
  const truncateText = (text, maxLength) => {
    return text?.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const handleEditModule = (module) => {
    setCurrentModule(module);
    setIsChapterCreation(true);
  };

  return (
    <Paper elevation={0} sx={{ borderRadius: 1, px: 2, py: 1, my: 2 }}>
      <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: 14 }} fontWeight={600}>
          {title}
        </Typography>
        <MDButton
          sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
          size="small"
          disabled={moduleLoading || !courseCode}
          onClick={() => {
            onAddModule();
            setIsChapterCreation(true);
          }}
        >
          <Icon>add_circle_outline</Icon>
          Add module
        </MDButton>
      </MDBox>
      {moduleLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" py={2}>
          <CircularProgress color="info" />
        </Box>
      ) : (
        moduleList.map((module) => (
          <Card
            // elevation={0}

            key={module.moduleCode}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
              my: 0.5,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontSize: 12, display: "flex", alignItems: "center", gap: 0.4, color: "gray" }}
              fontWeight={600}
            >
              <Icon>collections</Icon>
              {truncateText(module.moduleName, 30)}
            </Typography>
            <Box gap={0.4} sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="caption"
                fontWeight={600}
                sx={{
                  bgcolor: module.recordStatus === 1 ? "lightskyblue" : "lightgrey",
                  px: 1,
                  py: 0.3,
                  borderRadius: 3,
                  fontSize: 10,
                  //   color:'lightgrey'
                }}
              >
                {module.recordStatus === 1 ? "Published" : "Draft"}
              </Typography>
              <IconButton
                disabled={moduleLoading}
                onClick={() => handleEditModule(module)}
                size="small"
                sx={{ p: 0.4, m: 0, fontSize: 16 }}
              >
                <Icon>edit</Icon>
              </IconButton>
            </Box>
          </Card>
        ))
      )}
    </Paper>
  );
};

CourseModuleCard.propTypes = {
  title: PropTypes.string.isRequired,
  onAddModule: PropTypes.func.isRequired,
  isPublish: PropTypes.bool.isRequired,
  setIsChapterCreation: PropTypes.func.isRequired,
  moduleList: PropTypes.array.isRequired,
  moduleLoading: PropTypes.bool.isRequired,
  setCurrentModule: PropTypes.object.isRequired,
  courseCode: PropTypes.number.isRequired,
};

export default CourseModuleCard;
