import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import domtoimage from "dom-to-image";
import jsPDF from "jspdf";
import QRCode from "qrcode.react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import { UserService } from "services/service";
import { BASE_URL_QR } from "services/configUrls";
import MD5 from "crypto-js/md5"; // Import MD5 from crypto-js

const CertificateDownload = () => {
  const certificateRef = useRef(null);
  const qrCodeRef = useRef(null);
  const [certData, setCertData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const courseCode = sessionStorage.getItem("courseCode");
  const qrUrl = certData?.verificationUrl ? `${BASE_URL_QR}${certData?.verificationUrl}` : "";

  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        setLoading(true);
        const res = await UserService.getGenCertificate({ courseCode });
        const certificateData = res.data.responseData.data[0];

        // Generate certificate code using full MD5 hashing
        const certificateCode = generateCertificateCode(
          certificateData.name,
          certificateData.courseName,
          certificateData.certifiedDate
        );

        setCertData({ ...certificateData, certificateCode });
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError(true);
        setLoading(false);
      }
    };
    if (courseCode) {
      fetchCertificate();
    }
  }, [courseCode]);

  const generateCertificateCode = (name, courseName, certifiedDate) => {
    const dataToHash = `${name}-${courseName}-${certifiedDate}`;
    const hash = MD5(dataToHash).toString(); // Use full MD5 hash
    return hash; // Return full 32-character MD5 hash
  };

  const downloadCertificate = async () => {
    if (certificateRef.current && qrCodeRef.current) {
      const scale = 3; // Increase scale for higher resolution (higher value means better quality)

      // Convert the certificate DOM element to an image using dom-to-image
      const certificateImgData = await domtoimage.toPng(certificateRef.current, {
        quality: 1, // Maximum quality
        width: certificateRef.current.offsetWidth * scale,
        height: certificateRef.current.offsetHeight * scale,
        style: {
          transform: `scale(${scale})`,
          transformOrigin: "top left",
          width: certificateRef.current.offsetWidth + "px",
          height: certificateRef.current.offsetHeight + "px",
        },
      });

      // Generate PDF
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [
          certificateRef.current.offsetWidth * scale,
          certificateRef.current.offsetHeight * scale,
        ],
        compress: false,
      });

      pdf.addImage(
        certificateImgData,
        "PNG",
        0,
        0,
        certificateRef.current.offsetWidth * scale,
        certificateRef.current.offsetHeight * scale
      );
      pdf.save("certificate.pdf");
    }
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-IN", options);
  };

  return (
    <DashboardLayout>
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight={400}>
            <CircularProgress color="info" size={60} />
          </Box>
        ) : error ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight={400}>
            <Typography variant="h6" color="error">
              No Certificate Data Found or an Error Occurred
            </Typography>
          </Box>
        ) : !certData?.certificatePhoto ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight={400}>
            <Typography variant="h6">No Certificate Data Found</Typography>
          </Box>
        ) : certData?.certificatePhoto ? (
          <>
            <Box
              ref={certificateRef}
              position="relative"
              width="880px"
              height="620px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{
                backgroundImage: `url(${certData?.certificatePhoto})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            >
              <Typography
                variant="h3"
                fontWeight="bold"
                textAlign="center"
                fontSize={24}
                color="#194186"
                mb={0.5}
                mt={32.5}
                style={{
                  minWidth: "100%",
                  whiteSpace: "nowrap", // Prevent wrapping
                  textOverflow: "ellipsis", // Add ellipsis if text overflows
                  overflow: "hidden", // Restricting the width for word wrapping
                  // wordBreak: "break-word", // Ensuring long words break properly
                  // overflowWrap: "break-word", // For breaking words in a more browser compatible way
                }}
              >
                {certData?.name}
                {/* Suryadutta Dash my name is */}
              </Typography>
              <Typography
                style={{
                  minWidth: "100%",
                  whiteSpace: "nowrap", // Prevent wrapping
                  textOverflow: "ellipsis", // Add ellipsis if text overflows
                  overflow: "hidden", // Restricting the width for word wrapping
                  // wordBreak: "break-word", // Ensuring long words break properly
                  // overflowWrap: "break-word", // For breaking words in a more browser compatible way
                }}
                variant="h6"
                textAlign="center"
                color="black"
                mb={9.5}
                mt={4}
              >
                {certData?.courseName}
              </Typography>
              <Box>
                <Typography
                  variant="h6"
                  fontSize={12}
                  textAlign="center"
                  color="#000"
                  mr={73}
                  mt={0.5}
                  fontWeight={400}
                >
                  {formatDate(certData?.certifiedDate)}
                </Typography>
                <Typography
                  variant="h6"
                  fontSize={11}
                  fontWeight={400}
                  textAlign="center"
                  color="#000"
                  mr={58}
                >
                  {certData?.certificateCode}
                </Typography>
              </Box>
              <Box ref={qrCodeRef} position="absolute" bottom="80px" right="80px" padding="5px">
                <QRCode value={qrUrl} size={60} />
              </Box>
            </Box>
            <MDButton
              variant="contained"
              color="success"
              onClick={downloadCertificate}
              sx={{ mt: 4 }}
            >
              Download Certificate
            </MDButton>
          </>
        ) : (
          ""
        )}
      </Box>
    </DashboardLayout>
  );
};

export default CertificateDownload;
