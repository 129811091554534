// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { Paper, Typography, Icon, Box, CircularProgress, IconButton } from "@mui/material";
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import { AdminService } from "services/service";

// const CertificateCard = ({ title, courseCode }) => {
//   const [certificateUrl, setCertificateUrl] = useState("");
//   const [editing, setEditing] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [recordStatus, setRecordStatus] = useState(0); // Default to published

//   useEffect(() => {
//     const fetchCertificate = async () => {
//       if (courseCode) {
//         try {
//           const response = await AdminService.certificate({
//             courseCode,
//           });
//           //   setCertificateUrl(response.data.url); // Adjust based on your API response structure
//           console.log(response.data);
//         } catch (error) {
//           console.error("Error fetching certificate:", error);
//         } finally {
//           setLoading(false);
//         }
//       } else {
//         setLoading(false);
//       }
//     };

//     fetchCertificate();
//   }, [courseCode]);

//   const handleEdit = () => {
//     setEditing(true);
//   };

//   const handleCancel = () => {
//     setEditing(false);
//   };

//   const handleSave = async () => {
//     const formData = new FormData();
//     formData.append("opType", certificateUrl ? "UPDATE_CERTIFICATE" : "ADD_CERTIFICATE");
//     formData.append("courseCode", courseCode);
//     formData.append("recordStatus", recordStatus);
//     if (certificateUrl instanceof File) {
//       formData.append("file", certificateUrl);
//     }

//     try {
//       const response = await AdminService.certificate(formData);
//       //   setCertificateUrl(response.data.url); // Assuming the response contains the URL of the uploaded certificate
//       console.log(response.data);
//       setEditing(false);
//     } catch (error) {
//       console.error("Error saving certificate:", error);
//     }
//   };

//   const handleDelete = async () => {
//     const data = {
//       opType: "DELETE_CERTIFICATE",
//       courseCode,
//     };
//     try {
//       await api.post(API_URLS.Certificate, data);
//       setCertificateUrl("");
//     } catch (error) {
//       console.error("Error deleting certificate:", error);
//     }
//   };

//   const handleCertificateChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setCertificateUrl(file); // Set the File object directly
//       setEditing(false);
//     }
//   };

//   const isFile = (url) => {
//     return url instanceof File;
//   };

//   const handlePublish = () => {
//     setRecordStatus(1);
//     handleSave();
//   };

//   const handleUnpublish = () => {
//     setRecordStatus(0);
//     handleSave();
//   };

//   return (
//     <Paper elevation={0} sx={{ borderRadius: 1, px: 2, py: 1, my: 2 }}>
//       <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
//         <Typography sx={{ fontSize: 14 }} fontWeight={600}>
//           {title}
//         </Typography>
//         {editing ? (
//           <>
//             <MDButton size="small" onClick={handleSave}>
//               Save
//             </MDButton>
//             <MDButton size="small" onClick={handleCancel}>
//               Cancel
//             </MDButton>
//           </>
//         ) : (
//           <MDBox sx={{ display: "flex", alignItems: "center", gap: 1 }}>
//             <MDButton
//               sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
//               size="small"
//               disabled={!courseCode}
//               onClick={handleEdit}
//             >
//               <Icon>{certificateUrl ? "edit" : "add_circle_outline"}</Icon>
//               {certificateUrl ? "Change Certificate" : "Add Certificate"}
//             </MDButton>
//             {certificateUrl && (
//               <Box>
//                 <IconButton size="small" color="error" onClick={handleDelete}>
//                   <Icon>delete</Icon>
//                 </IconButton>
//                 <IconButton size="small" color="success" onClick={handleDelete}>
//                   <Icon>save</Icon>
//                 </IconButton>
//                 <IconButton
//                   size="small"
//                   color={recordStatus === 1 ? "info" : "secondary"}
//                   onClick={() => setRecordStatus(recordStatus === 1 ? 0 : 1)}
//                 >
//                   <Icon> {recordStatus === 1 ? "remove_red_eye" : "visibility_off"}</Icon>
//                 </IconButton>
//               </Box>
//             )}
//           </MDBox>
//         )}
//       </MDBox>
//       {loading ? (
//         <Box display="flex" justifyContent="center" alignItems="center">
//           <CircularProgress color="info" />
//         </Box>
//       ) : editing ? (
//         <input
//           type="file"
//           accept="image/png, image/jpeg"
//           onChange={handleCertificateChange}
//           style={{ display: "block", marginBottom: "1rem" }}
//         />
//       ) : (
//         certificateUrl && (
//           <img
//             src={isFile(certificateUrl) ? URL.createObjectURL(certificateUrl) : certificateUrl}
//             alt="Certificate"
//             style={{ width: "100%", marginTop: "1rem" }}
//           />
//         )
//       )}
//     </Paper>
//   );
// };

// CertificateCard.propTypes = {
//   title: PropTypes.string.isRequired,
//   courseCode: PropTypes.number.isRequired,
// };

// export default CertificateCard;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, Typography, Icon, Box, CircularProgress, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { AdminService } from "services/service";
import { handleSuccessMessage } from "services/service";
import { handleErrMessage } from "services/service";

const CertificateCard = ({ title, courseCode }) => {
  const [certificateUrl, setCertificateUrl] = useState(null);
  const [getCertificateUrl, setGetCertificateUrl] = useState(null);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [recordStatus, setRecordStatus] = useState(0); // Default to unpublished

  useEffect(() => {
    return () => {
      // This will run when the component unmounts
      setGetCertificateUrl(null);
      setCertificateUrl(null);
      setEditing(false);
    };
  }, []);

  const fetchCertificate = async () => {
    if (courseCode) {
      try {
        setLoading(true);
        const response = await AdminService.certificate({ courseCode });
        setCertificateUrl(response.data?.responseData?.certificatePhoto || null); // Adjust based on your API
        setGetCertificateUrl(response.data?.responseData?.certificatePhoto || null);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching certificate:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCertificate();
  }, [courseCode]);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleSaveAdd = async () => {
    const formData = new FormData();
    formData.append("opType", "ADD_CERTIFICATE");
    formData.append("courseCode", courseCode);
    formData.append("recordStatus", recordStatus);
    if (certificateUrl instanceof File) {
      formData.append("file", certificateUrl);
    }
    if (courseCode) {
      try {
        setLoading(true);
        const response = await AdminService.certificate(formData);
        fetchCertificate();
        setEditing(false);
        handleSuccessMessage(response.data?.message);
      } catch (error) {
        console.error("Error saving certificate:", error);
        handleErrMessage(error.response?.data?.message || "An unexpected error occurred.");
        setLoading(false);
      }
    } else {
      console.error("Error saving certificate....");
      setLoading(false);
    }
  };

  const handleSaveUpdate = async () => {
    const formData = new FormData();
    formData.append("opType", "UPDATE_CERTIFICATE");
    formData.append("courseCode", courseCode);
    formData.append("recordStatus", recordStatus);
    if (certificateUrl instanceof File) {
      formData.append("file", certificateUrl);
    }
    if (courseCode) {
      try {
        setLoading(true);
        const response = await AdminService.certificate(formData);
        fetchCertificate();
        setEditing(false);
        handleSuccessMessage(response.data?.message);
        setLoading(false);
      } catch (error) {
        console.error("Error saving certificate:", error);
        handleErrMessage(error.response?.data?.message || "An unexpected error occurred.");
        setLoading(false);
      }
    } else {
      console.error("Error saving certificate....");
    }
  };

  const handleDelete = async () => {
    if (isFile(certificateUrl)) {
      setCertificateUrl(null); // Only clear the state if it's a File
    } else {
      const data = {
        opType: "DELETE_CERTIFICATE",
        courseCode,
      };
      try {
        setLoading(true);
        const res = await AdminService.certificate(data);
        setCertificateUrl(null);
        handleSuccessMessage(res.data?.message);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error deleting certificate:", error);
        handleErrMessage(error.response?.data?.message || "An unexpected error occurred.");
      }
    }
  };

  const handleCertificateChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCertificateUrl(file); // Set the File object directly
      setEditing(false);
    }
  };

  const isFile = (url) => {
    return url instanceof File;
  };

  const handlePublish = () => {
    setRecordStatus(0);
  };

  const handleUnpublish = () => {
    setRecordStatus(1);
  };
  return (
    <Paper elevation={0} sx={{ borderRadius: 1, px: 2, py: 1, my: 2 }}>
      <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: 14 }} fontWeight={600}>
          {title}
        </Typography>
        {editing ? (
          <>
            <MDButton size="small" onClick={handleCancel}>
              Cancel
            </MDButton>
          </>
        ) : (
          <MDBox sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <MDButton
              sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
              size="small"
              disabled={!courseCode}
              onClick={handleEdit}
            >
              <Icon>{certificateUrl ? "edit" : "add_circle_outline"}</Icon>
              {certificateUrl ? "Change" : "Add Certificate"}
            </MDButton>
            {certificateUrl && (
              <Box>
                <IconButton
                  size="small"
                  color="success"
                  onClick={getCertificateUrl ? handleSaveUpdate : handleSaveAdd}
                  disabled={loading}
                >
                  <Icon>save</Icon>
                </IconButton>
                <IconButton
                  size="small"
                  disabled={loading}
                  color={recordStatus === 1 ? "info" : "secondary"}
                  onClick={recordStatus === 1 ? handlePublish : handleUnpublish}
                >
                  <Icon>{recordStatus === 0 ? "visibility_off" : "remove_red_eye"}</Icon>
                </IconButton>
                <IconButton disabled={loading} size="small" color="error" onClick={handleDelete}>
                  <Icon>delete</Icon>
                </IconButton>
              </Box>
            )}
          </MDBox>
        )}
      </MDBox>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" my={2}>
          <CircularProgress color="info" />
        </Box>
      ) : editing ? (
        <input
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleCertificateChange}
          style={{ display: "block", marginBottom: "1rem" }}
        />
      ) : (
        certificateUrl && (
          <img
            src={isFile(certificateUrl) ? URL.createObjectURL(certificateUrl) : certificateUrl}
            alt={isFile(certificateUrl) ? "File not found" : "bad url"}
            style={{ width: "100%", marginTop: "1rem" }}
          />
        )
      )}
    </Paper>
  );
};

CertificateCard.propTypes = {
  title: PropTypes.string.isRequired,
  courseCode: PropTypes.number.isRequired,
};

export default CertificateCard;
