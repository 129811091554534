import React from "react";
import { Link } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";

export default function data(tableData) {
  const Course = ({ name, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      {/* <MDTypography variant="caption">{description}</MDTypography> */}
    </MDBox>
  );

  const Instructor = ({ name, email }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  );

  Course.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };

  Instructor.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  const handleActionClick = (rowData) => {
    localStorage.setItem("editCourse", JSON.stringify(rowData));
    // Perform any other action you need here
  };

  return {
    columns: [
      { Header: "Course", accessor: "course", width: "45%", align: "left" },
      { Header: "Course Code", accessor: "courseCode", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: tableData.map((dataItem, index) => ({
      course: (
        <Course
          name={dataItem.courseName}
          // description={dataItem.description ? dataItem.description : ""}
        />
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={dataItem.recordStatus === 1 ? "publish" : "unPublish"}
            color={dataItem.recordStatus === 1 ? "success" : "dark"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      courseCode: (
        <MDBox ml={-1}>
          <strong>{dataItem.courseCode}</strong>
        </MDBox>
      ),
      action: (
        <Link to="/courses/course" onClick={() => handleActionClick(dataItem)}>
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        </Link>
      ),
    })),
  };
}
