import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./Slices/auth/authSlice";
import authoriseReducer from "./Slices/auth/authoriseSlice";
import appSlice from "./Slices/appSlice";

export const rootReducer = combineReducers({
  auth: authReducer,
  authorise: authoriseReducer,
  app: appSlice,
});
