import React, { createContext, useContext, useState, useEffect } from "react";
import NetworkErrorModal from "./NetworkErrorModal";
import PropTypes from "prop-types";

const NetworkErrorContext = createContext();

export const NetworkErrorProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const showNetworkError = () => {
    setOpen(true);
  };

  const hideNetworkError = () => {
    setOpen(false);
  };

  useEffect(() => {
    setNetworkErrorFunction(showNetworkError);
  }, []);

  return (
    <NetworkErrorContext.Provider value={showNetworkError}>
      {children}
      <NetworkErrorModal open={open} onClose={hideNetworkError} />
    </NetworkErrorContext.Provider>
  );
};

// Define prop types for the component
NetworkErrorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNetworkError = () => {
  return useContext(NetworkErrorContext);
};

let networkErrorFunction = null;

export const setNetworkErrorFunction = (func) => {
  networkErrorFunction = func;
};

export const showNetworkErrorExternally = () => {
  if (networkErrorFunction) {
    networkErrorFunction();
  }
};
