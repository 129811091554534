// import { Card, Icon, Paper, Typography } from "@mui/material";
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import React, { useState } from "react";
// import PropTypes from "prop-types";

// const PdfUploadCard = ({ title, pdfUrl, setPdfUrl }) => {
//   const [editing, setEditing] = useState(false);

//   const handleEdit = () => {
//     setEditing(true);
//   };

//   const handleCancel = () => {
//     setEditing(false);
//     setPdfUrl(null);
//   };

//   const handleSave = () => {
//     setEditing(false);
//   };

//   const handlePdfChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setPdfUrl(file);
//       setEditing(false);
//     }
//   };

//   const truncateUrl = (url, length) => {
//     if (!url) return ""; // Return empty string or handle appropriately
//     if (url.length <= length) return url;
//     return url?.slice(0, length) + "...";
//   };

//   return (
//     <Paper elevation={0} sx={{ borderRadius: 1, px: 2, py: 1, my: 2 }}>
//       <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
//         <Typography sx={{ fontSize: 14 }} fontWeight={600}>
//           {title}
//         </Typography>
//         {editing ? (
//           <MDButton size="small" onClick={handleCancel}>
//             Cancel
//           </MDButton>
//         ) : (
//           <MDButton
//             sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
//             size="small"
//             onClick={handleEdit}
//           >
//             <Icon>{pdfUrl ? "edit" : "add_circle_outline"}</Icon>
//             {pdfUrl ? "Change PDF" : "Add PDF"}
//           </MDButton>
//         )}
//       </MDBox>
//       {editing ? (
//         <>
//           <input
//             type="file"
//             accept="application/pdf"
//             onChange={handlePdfChange}
//             style={{ display: "block", marginBottom: "1rem" }}
//           />
//         </>
//       ) : (
//         pdfUrl && (
//           <Typography variant="caption" style={{ marginTop: "1rem" }}>
//             Uploaded PDF: {truncateUrl(pdfUrl.name || pdfUrl, 50)}
//           </Typography>
//         )
//       )}
//     </Paper>
//   );
// };

// PdfUploadCard.propTypes = {
//   title: PropTypes.string.isRequired,
//   pdfUrl: PropTypes.string,
//   setPdfUrl: PropTypes.func.isRequired,
// };

// export default PdfUploadCard;

import { Card, Icon, Paper, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useState } from "react";
import PropTypes from "prop-types";

const PdfUploadCard = ({ title, pdfUrl, setPdfUrl }) => {
  const [editing, setEditing] = useState(false);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
    setPdfUrl(null);
  };

  const handleSave = () => {
    setEditing(false);
  };

  const handlePdfChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPdfUrl(file);
      setEditing(false);
    }
  };

  const truncateUrl = (url, length) => {
    if (!url) return ""; // Return empty string or handle appropriately
    const name = url.name || url;
    if (name.length <= length) return name;
    return name.slice(0, length) + "...";
  };

  return (
    <Paper elevation={0} sx={{ borderRadius: 1, px: 2, py: 1, my: 2 }}>
      <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: 14 }} fontWeight={600}>
          {title}
        </Typography>
        {editing ? (
          <MDButton size="small" onClick={handleCancel}>
            Cancel
          </MDButton>
        ) : (
          <MDButton
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
            size="small"
            onClick={handleEdit}
          >
            <Icon>{pdfUrl ? "edit" : "add_circle_outline"}</Icon>
            {pdfUrl ? "Change PDF" : "Add PDF"}
          </MDButton>
        )}
      </MDBox>
      {editing ? (
        <>
          <input
            type="file"
            accept="application/pdf"
            onChange={handlePdfChange}
            style={{ display: "block", marginBottom: "1rem" }}
          />
        </>
      ) : (
        pdfUrl && (
          <Typography variant="caption" style={{ marginTop: "1rem" }}>
            Uploaded PDF: {truncateUrl(pdfUrl, 50)}
          </Typography>
        )
      )}
    </Paper>
  );
};

PdfUploadCard.propTypes = {
  title: PropTypes.string.isRequired,
  pdfUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setPdfUrl: PropTypes.func.isRequired,
};

export default PdfUploadCard;
