// Santanu Garnaik Design

// @mui material components
import Grid from "@mui/material/Grid";

// Santanu Garnaik Design React components
import MDBox from "components/MDBox";

// Santanu Garnaik Design React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function Dashboard() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={30}></MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
