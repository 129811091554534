// import { Box, CircularProgress, Container, Grid } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import CourseCard from "./CourseCard";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import { UserService } from "services/service";

// const DashboardStudent = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [course, setCourse] = useState([]);
//   const getCourseForUser = async () => {
//     try {
//       setIsLoading(true);
//       const res = await UserService.getCourseForUser();
//       console.log("getCourseForUser", res.data.responseData?.data);
//       setCourse(res.data?.responseData?.data);
//       setIsLoading(false);
//     } catch (error) {
//       console.error(error);
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     getCourseForUser();
//   }, []);

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <Grid container spacing={1} mb={2} sx={{ minHeight: "75vh" }}>
//         {isLoading ? (
//           <Box
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             minHeight={300}
//             width="100%"
//           >
//             <CircularProgress color="info" size={70} />
//           </Box>
//         ) : (
//           course?.map((course, index) => (
//             <Grid item xs={12} sm={6} md={3} key={index}>
//               <CourseCard course={course} />
//             </Grid>
//           ))
//         )}
//       </Grid>
//       <Footer />
//     </DashboardLayout>
//   );
// };

// export default DashboardStudent;

import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import CourseCard from "./CourseCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { UserService } from "services/service";

const DashboardStudent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [course, setCourse] = useState([]);

  const getCourseForUser = async () => {
    try {
      setIsLoading(true);
      const res = await UserService.getCourseForUser();
      console.log("getCourseForUser", res.data.responseData?.data);
      setCourse(res.data?.responseData?.data || []); // Ensure course is always an array
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCourseForUser();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={1} mb={2} sx={{ minHeight: "75vh" }}>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={300}
            width="100%"
          >
            <CircularProgress color="info" size={70} />
          </Box>
        ) : course.length > 0 ? (
          course.map((course, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <CourseCard course={course} />
            </Grid>
          ))
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={300}
            width="100%"
          >
            <Typography variant="h5" color="textSecondary">
              No courses available
            </Typography>
          </Box>
        )}
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default DashboardStudent;
