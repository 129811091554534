// import { Card, TextField, Autocomplete, Typography, Paper, Button, Icon } from "@mui/material";
// import MDBox from "components/MDBox";
// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import MDButton from "components/MDButton";

// const BulkQuestionUploadCard = ({
//   title,
//   questionType,
//   setQuestionType,
//   csvFile,
//   setCsvFile,
//   courseCode,
// }) => {
//   const questionTypes = ["Multi", "Single", "True/False"]; // Example question types
//   const [editing, setEditing] = useState(false);

//   const handleEdit = () => {
//     setEditing(true);
//   };

//   const handleCancel = () => {
//     setEditing(false);
//     setQuestionType("");
//     // setCsvFile(null);
//   };
//   const handleQuestionTypeChange = (event, newValue) => {
//     setQuestionType(newValue);
//   };

//   const handleFileChange = (event) => {
//     setCsvFile(event.target.files[0]);
//   };

//   return (
//     <Paper elevation={0} sx={{ borderRadius: 1, px: 2, py: 1, my: 2 }}>
//       <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
//         <Typography sx={{ fontSize: 14 }} fontWeight={600}>
//           {title}
//         </Typography>
//         {editing ? (
//           <MDButton size="small" onClick={handleCancel}>
//             Cancel
//           </MDButton>
//         ) : (
//           <MDButton
//             sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
//             size="small"
//             onClick={handleEdit}
//             disabled={!courseCode}
//           >
//             <Icon>add_circle_outline</Icon>
//             Add
//           </MDButton>
//         )}
//       </MDBox>

//       <MDBox>
//         {/* <Autocomplete
//             options={questionTypes}
//             value={questionType}
//             onChange={handleQuestionTypeChange}
//             renderInput={(params) => (
//               <TextField {...params} label="Question Type" size="small" variant="outlined" />
//             )}
//             fullWidth
//             sx={{ mb: 1 }}
//           /> */}
//         {/* {questionTypes && ( */}
//         {editing && (
//           <Button
//             color="info"
//             // disabled={!questionType}
//             variant="contained"
//             component="label"
//             fullWidth
//             sx={{ my: 1 }}
//           >
//             Upload CSV
//             <input
//               // disabled={!questionType}
//               type="file"
//               accept=".csv"
//               hidden
//               onChange={handleFileChange}
//             />
//           </Button>
//           // {/* )} */}
//         )}
//         {csvFile && (
//           <Typography variant="caption">
//             {csvFile.name || `${csvFile.substring(0, 30)}...`}
//           </Typography>
//         )}
//       </MDBox>
//     </Paper>
//   );
// };

// BulkQuestionUploadCard.propTypes = {
//   title: PropTypes.string.isRequired,
//   questionType: PropTypes.string.isRequired,
//   setQuestionType: PropTypes.func.isRequired,
//   csvFile: PropTypes.object,
//   setCsvFile: PropTypes.func.isRequired,
//   courseCode: PropTypes.any.isRequired,
// };

// export default BulkQuestionUploadCard;

import {
  Card,
  TextField,
  Autocomplete,
  Typography,
  Paper,
  Button,
  Icon,
  IconButton,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import React, { useState } from "react";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import questionFormat from "./questions-format.csv"; // Ensure this path is correct

const BulkQuestionUploadCard = ({
  title,
  questionType,
  setQuestionType,
  csvFile,
  setCsvFile,
  courseCode,
}) => {
  const questionTypes = ["Multi", "Single", "True/False"]; // Example question types
  const [editing, setEditing] = useState(false);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
    setQuestionType("");
  };

  const handleQuestionTypeChange = (event, newValue) => {
    setQuestionType(newValue);
  };

  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]);
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement("a");
    link.href = questionFormat;
    link.setAttribute("download", "questions-format.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Paper elevation={0} sx={{ borderRadius: 1, px: 2, py: 1, my: 2 }}>
      <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: 14 }} fontWeight={600}>
          {title}
        </Typography>
        {editing ? (
          <MDButton size="small" onClick={handleCancel}>
            Cancel
          </MDButton>
        ) : (
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <MDButton
              sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
              size="small"
              onClick={handleEdit}
              disabled={!courseCode}
            >
              <Icon>
                {(!csvFile && "add_circle_outline") || csvFile?.name
                  ? "add_circle_outline"
                  : "edit"}
              </Icon>
              {(!csvFile && "Add") || csvFile?.name ? "Add" : "Change"}
            </MDButton>
            <Tooltip title="Download CSV Format">
              <IconButton size="small" sx={{ p: 0.2 }} onClick={handleDownloadTemplate}>
                <Icon>download</Icon>
              </IconButton>
            </Tooltip>
          </MDBox>
        )}
      </MDBox>

      <MDBox>
        {editing && (
          <Button color="info" variant="contained" component="label" fullWidth sx={{ my: 1 }}>
            Upload CSV
            <input type="file" accept=".csv" hidden onChange={handleFileChange} />
          </Button>
        )}
        {csvFile && (
          <Typography variant="caption">
            {" "}
            {csvFile.name || `${csvFile.substring(0, 30)}...`}
          </Typography>
        )}
      </MDBox>
    </Paper>
  );
};

BulkQuestionUploadCard.propTypes = {
  title: PropTypes.string.isRequired,
  questionType: PropTypes.string.isRequired,
  setQuestionType: PropTypes.func.isRequired,
  csvFile: PropTypes.object,
  setCsvFile: PropTypes.func.isRequired,
  courseCode: PropTypes.any.isRequired,
};

export default BulkQuestionUploadCard;
