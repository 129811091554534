// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
// Santanu Garnaik Design React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Santanu Garnaik Design React example components

import DataTable from "examples/Tables/DataTable";

import courseTableData from "layouts/tables/data/courseTableData";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";

function Tables({ tableData, isLoading }) {
  const { columns: cColumns, rows: cRows } = courseTableData(tableData);

  return (
    <MDBox pt={6} pb={3} minHeight={"76vh"}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={1}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h6" color="white">
                Courses Table
              </MDTypography>
              <MDButton size="small" component={Link} to="/courses/course">
                Add Course
              </MDButton>
            </MDBox>
            <MDBox pt={3}>
              {isLoading ? (
                <MDBox display="flex" justifyContent="center" alignItems="center" my={3}>
                  <CircularProgress color="info" />
                </MDBox>
              ) : (
                <DataTable
                  table={{ columns: cColumns, rows: cRows }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={true}
                  noEndBorder
                />
              )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

Tables.propTypes = {
  tableData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Tables;
