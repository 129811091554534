// appSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  studentModule: false,
  routes: [],
  selectedModuleCode: null,
  selectedModuleData: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setStudentModule: (state, action) => {
      state.studentModule = action.payload;
    },
    setRoutes: (state, action) => {
      state.routes = action.payload;
    },
    setSelectedModuleCode: (state, action) => {
      state.selectedModuleCode = action.payload;
    },
    setSelectedModuleData: (state, action) => {
      state.selectedModuleData = action.payload;
    },
  },
});

export const { setStudentModule, setRoutes, setSelectedModuleCode, setSelectedModuleData } =
  appSlice.actions;

export const selectStudentModule = (state) => state.app.studentModule;
export const selectRoutes = (state) => state.app.routes;
export const selectSelectedModuleCode = (state) => state.app.selectedModuleCode;
export const selectSelectedModuleData = (state) => state.app.selectedModuleData;

export default appSlice.reducer;
