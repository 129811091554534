// Santanu Garnaik Design
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Tables from "layouts/tables";
import { useEffect, useState } from "react";
import { AdminService } from "services/service";

function Course() {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await AdminService.course({});
      setTableData(res.data?.responseData?.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Tables tableData={tableData} isLoading={isLoading} />
      <Footer />
    </DashboardLayout>
  );
}

export default Course;
