import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CourseModules from "./courseModule";

const CourseModule = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={1}>
        <CourseModules />
      </MDBox>

      {/* <Footer /> */}
    </DashboardLayout>
  );
};

export default CourseModule;
