import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import { Box } from "@mui/material";
import { UserService } from "services/service";

const CourseVideoModule = ({
  videoSrc,
  title,
  description,
  setCurrentIndex,
  isCompleted,
  courseCode,
  moduleCode,
}) => {
  const [isComplete, setIsComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const videoRef = useRef(null);

  const handleVideoEnd = () => {
    setIsComplete(true);
  };

  const handleMarkAsComplete = async () => {
    if (isComplete && isCompleted) {
      // Implement the logic when the video is marked as complete
      setCurrentIndex((pre) => pre + 1);
    } else if (isComplete && moduleCode && courseCode) {
      try {
        setLoading(true);
        const res = await UserService.progress({
          courseCode: courseCode,
          moduleCode: moduleCode,
          isDocumentComplete: 0,
          isVideoComplete: 1,
          isQuestionComplete: 0,
          isFinalQuestionComplete: 0,
          recordStatus: 1,
          opType: "UPDATE_PROGRESS",
        });

        setCurrentIndex((pre) => pre + 1);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  return (
    <MDBox py={1} px={3}>
      <video
        ref={videoRef}
        width="100%"
        controls
        onEnded={handleVideoEnd}
        controlsList="nodownload" // Remove download option
        style={{ borderRadius: "15px", maxHeight: "480px" }} // Add border radius
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <MDBox mt={0.6}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h4" fontSize={18} gutterBottom>
            {title}
          </Typography>
          <MDButton
            size="small"
            color="info"
            onClick={handleMarkAsComplete}
            disabled={!isComplete || loading}
          >
            Mark as Complete
          </MDButton>
        </Box>
        <Typography variant="body1" mt={1} fontSize={14} paragraph>
          {description}
        </Typography>
      </MDBox>
    </MDBox>
  );
};

CourseVideoModule.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  setCurrentIndex: PropTypes.number.isRequired,
  isCompleted: PropTypes.any,
  courseCode: PropTypes.any.isRequired,
  moduleCode: PropTypes.any.isRequired,
};

export default CourseVideoModule;
