import { useNavigation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearAuthorise } from "./Slices/auth/authoriseSlice"; // Assuming the auth slice file path

export const handleUnauthorizedError = (error) => {
  const navigate = useNavigation();
  const dispatch = useDispatch();

  if (
    error.response &&
    error.response.status === 401 &&
    error.response.data.message === "No token provided"
  ) {
    // Clear Redux state
    dispatch(clearAuthorise());

    // Navigate to login page
    navigate("/login");
  }
};
