import { Card, Icon, TextField, Autocomplete, Typography, Paper } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AdminService } from "services/service";

const CourseCard = ({ title, value, setValue, type, categoryCode }) => {
  const [editing, setEditing] = useState(false);
  const [categories, setCategories] = useState([]);
  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleSave = () => {
    setEditing(false);
  };

  const handleChange = (event, newValue) => {
    if (newValue) {
      const selectedCategory = categories.find((category) => category.categoryName === newValue);
      setValue(selectedCategory); // Send entire category object { categoryCode, categoryName } to parent component
    } else {
      setValue(null); // Handle the case when no category is selected
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await AdminService.getCategoryDropdown();
        setCategories(res.data.responseData.data || []);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Paper elevation={0} sx={{ borderRadius: 1, px: 2, py: 1, my: 2 }}>
      <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: 14 }} fontWeight={600}>
          {title}
        </Typography>
        {editing ? (
          <MDButton size="small" onClick={handleCancel}>
            Cancel
          </MDButton>
        ) : (
          <MDButton
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
            size="small"
            onClick={handleEdit}
            disabled={typeof value === "number"}
          >
            <Icon>{value === null || value?.length === 0 ? "add_circle_outline" : "edit"}</Icon>
            {value === null || value?.length === 0 ? `Add ${type}` : `Edit`}
          </MDButton>
        )}
      </MDBox>

      {editing ? (
        <>
          {type === "Category" ? (
            <Autocomplete
              options={categories?.map((category) => category.categoryName)} // Use categoryName for display
              value={value?.categoryName || value} // Display selected categoryName
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} variant="standard" />}
              autoFocus
              fullWidth
              sx={{ mb: 1 }}
            />
          ) : (
            <TextField
              value={typeof value === "string" ? value : value?.categoryName || ""} // Display category name for description or other types
              onChange={(e) => setValue({ categoryName: e.target.value })} // Update categoryName directly
              autoFocus
              fullWidth
              type={type === "code" ? "number" : "text"}
              multiline={type === "Description"}
              variant="standard"
              sx={{ mb: 1 }}
            />
          )}
          <MDButton size="small" onClick={handleSave}>
            Save
          </MDButton>
        </>
      ) : (
        <Typography variant="caption" sx={{ fontWeight: 500, lineHeight: 0.2 }} color="gray">
          {typeof value === "string" || typeof value === "number"
            ? value
            : value?.categoryName || ""}
          {/* Display category name when not editing */}
        </Typography>
      )}
    </Paper>
  );
};

CourseCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  categoryCode: PropTypes.number,
};

export default CourseCard;
