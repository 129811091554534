import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Icon, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AdminService } from "services/service";

const AddCategory = () => {
  const [editing, setEditing] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryCode, setCategoryCode] = useState(null); // Used for updating category

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const res = await AdminService.getCategoryDropdown();
      setCategories(res.data.responseData.data || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleEditCategory = (category) => {
    setEditing(true);
    setCategoryName(category.categoryName);
    setCategoryCode(category.categoryCode);
  };

  const handleCancel = () => {
    setEditing(false);
    setCategoryName("");
    setCategoryCode(null);
  };

  const handleSave = async () => {
    if (!categoryName.trim()) {
      console.error("Category name cannot be empty.");
      return;
    }

    if (editing && categoryCode) {
      // Update existing category
      const data = {
        categoryCode,
        categoryName,
        recordStatus: 1,
        opType: "UPDATE_CATEGORY",
      };
      try {
        const response = await AdminService.category(data);
        fetchCategories();
        setEditing(false);
        setCategoryName("");
        setCategoryCode(null);
      } catch (error) {
        console.error("Error updating category:", error);
      }
    } else {
      // Add new category
      const data = {
        categoryName,
        recordStatus: 1,
        opType: "ADD_CATEGORY",
      };
      try {
        const response = await AdminService.category(data);
        fetchCategories();
        setEditing(false);
        setCategoryName("");
      } catch (error) {
        console.error("Error adding category:", error);
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3} sx={{ minHeight: "78vh" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper elevation={0} sx={{ borderRadius: 1, px: 2, py: 1, my: 2, height: "100%" }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                All Categories
              </Typography>
              {categories.map((category) => (
                <Paper
                  key={category.categoryCode}
                  onClick={() => handleEditCategory(category)}
                  sx={{ cursor: "pointer", mb: 1, px: 2, py: 0.5 }}
                >
                  <Typography variant="h6" sx={{ fontSize: 12, color: "GrayText" }}>
                    {category.categoryName}
                  </Typography>
                </Paper>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper elevation={0} sx={{ borderRadius: 1, px: 2, py: 1, my: 2 }}>
              <MDBox
                sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
              >
                <Typography sx={{ fontSize: 14 }} fontWeight={600}>
                  {editing ? "Edit Category" : "Add Category"}
                </Typography>
                {editing ? (
                  <MDButton size="small" onClick={handleCancel}>
                    Cancel
                  </MDButton>
                ) : (
                  <MDButton
                    sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    size="small"
                    onClick={() => setEditing(true)}
                  >
                    <Icon>add_circle_outline</Icon>
                    Add Category
                  </MDButton>
                )}
              </MDBox>

              {editing && (
                <>
                  <TextField
                    label="Category Name"
                    variant="standard"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    autoFocus
                    fullWidth
                    sx={{ mb: 1 }}
                  />
                  <MDButton size="small" onClick={handleSave}>
                    Save
                  </MDButton>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AddCategory;
