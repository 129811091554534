// import { Card, Icon, IconButton, Paper, Typography } from "@mui/material";
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import React, { useState } from "react";
// import PropTypes from "prop-types";

// const VideoCard = ({ title, videoUrl, setVideoUrl }) => {
//   const [editing, setEditing] = useState(false);

//   const handleEdit = () => {
//     setEditing(true);
//   };

//   const handleCancel = () => {
//     setEditing(false);
//   };

//   const handleSave = () => {
//     setEditing(false);
//   };

//   const handleVideoChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setVideoUrl(file);
//       setEditing(false);
//     }
//   };

//   return (
//     <Paper elevation={0} sx={{ borderRadius: 1, px: 2, py: 1, my: 2 }}>
//       <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
//         <Typography sx={{ fontSize: 14 }} fontWeight={600}>
//           {title}
//         </Typography>
//         {editing ? (
//           <MDButton size="small" onClick={handleCancel}>
//             Cancel
//           </MDButton>
//         ) : (
//           <MDButton
//             sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
//             size="small"
//             onClick={handleEdit}
//           >
//             <Icon>{videoUrl ? "edit" : "add_circle_outline"}</Icon>
//             {videoUrl ? "Change Video" : "Add Video"}
//           </MDButton>
//         )}
//       </MDBox>
//       {editing ? (
//         <>
//           <input
//             type="file"
//             accept="video/*"
//             onChange={handleVideoChange}
//             style={{ display: "block", marginBottom: "1rem" }}
//           />
//         </>
//       ) : (
//         videoUrl && (
//           <video
//             src={URL.createObjectURL(videoUrl)}
//             controls
//             style={{ width: "100%", marginTop: "1rem" }}
//           />
//         )
//       )}
//     </Paper>
//   );
// };

// VideoCard.propTypes = {
//   title: PropTypes.string.isRequired,
//   videoUrl: PropTypes.object, // Updated to expect a file object
//   setVideoUrl: PropTypes.func.isRequired,
// };

// export default VideoCard;

import { Icon, Paper, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useState } from "react";
import PropTypes from "prop-types";

const VideoCard = ({ title, videoUrl, setVideoUrl }) => {
  const [editing, setEditing] = useState(false);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleSave = () => {
    setEditing(false);
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type.startsWith("video/") || file.type === "")) {
      setVideoUrl(file);
      setEditing(false);
    } else {
      console.error("Invalid file selected or no file selected.");
    }
  };

  const createVideoUrl = (videoUrl) => {
    try {
      if (typeof videoUrl === "object" && videoUrl instanceof File) {
        return URL.createObjectURL(videoUrl);
      } else if (typeof videoUrl === "string") {
        return videoUrl; // Assume it's already a valid URL
      } else {
        return "";
      }
    } catch (error) {
      console.error("Failed to create object URL for video:", error);
      return "";
    }
  };

  return (
    <Paper elevation={0} sx={{ borderRadius: 1, px: 2, py: 1, my: 2 }}>
      <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: 14 }} fontWeight={600}>
          {title}
        </Typography>
        {editing ? (
          <MDButton size="small" onClick={handleCancel}>
            Cancel
          </MDButton>
        ) : (
          <MDButton
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
            size="small"
            onClick={handleEdit}
          >
            <Icon>{videoUrl ? "edit" : "add_circle_outline"}</Icon>
            {videoUrl ? "Change Video" : "Add Video"}
          </MDButton>
        )}
      </MDBox>
      {editing ? (
        <input
          type="file"
          accept="video/*"
          onChange={handleVideoChange}
          style={{ display: "block", marginBottom: "1rem" }}
        />
      ) : (
        videoUrl &&
        (typeof videoUrl === "object" || typeof videoUrl === "string") && (
          <video
            src={createVideoUrl(videoUrl)}
            controls
            style={{ width: "100%", marginTop: "1rem" }}
          />
        )
      )}
    </Paper>
  );
};

VideoCard.propTypes = {
  title: PropTypes.string.isRequired,
  videoUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // Updated to accept either object (File) or string
  setVideoUrl: PropTypes.func.isRequired,
};

export default VideoCard;
