import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";
import store from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Santanu Garnaik Design React Context Provider
import { MaterialUIControllerProvider } from "context";
import { NetworkErrorProvider } from "./utils";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Provider store={store}>
        <NetworkErrorProvider>
          <App />
        </NetworkErrorProvider>
      </Provider>
      <ToastContainer />
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
