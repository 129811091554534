// import React, { useState } from "react";
// import MDBox from "components/MDBox";
// import Footer from "examples/Footer";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import { UserService } from "services/service";
// import { handleErrMessage, handleSuccessMessage } from "services/service";
// import { Button, Typography, CircularProgress } from "@mui/material";
// import MDButton from "components/MDButton";

// const AddStudents = () => {
//   const [csvFileSm, setCsvFileSm] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);

//   const handleFileChange = (event) => {
//     setCsvFileSm(event.target.files[0]);
//   };

//   const addStudent = async () => {
//     const formData = new FormData();
//     if (csvFileSm) {
//       formData.append("file", csvFileSm);
//     }
//     console.log("FormData student:", ...formData);

//     try {
//       setIsLoading(true);
//       console.warn("Loading...");
//       console.log(formData);
//       const res = await UserService.createUser(formData);
//       console.log("Student updated successfully:", res.data);
//       console.warn("Load Done");
//       handleSuccessMessage(res.data.message);
//       setCsvFileSm(null);
//     } catch (error) {
//       console.warn("Load Error");
//       console.error("An error occurred while updating the Student:", error);
//       handleErrMessage(error.response?.data?.message || "An unexpected error occurred.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox
//         py={4}
//         minHeight={"76vh"}
//         display="flex"
//         flexDirection="column"
//         alignItems="center"
//         justifyContent="center"
//         bgcolor="#f4f6f8"
//       >
//         <MDBox
//           width="100%"
//           maxWidth="500px"
//           p={3}
//           boxShadow={3}
//           borderRadius="10px"
//           bgcolor="white"
//           display="flex"
//           flexDirection="column"
//           alignItems="center"
//         >
//           <Typography variant="h4" gutterBottom>
//             Add Students
//           </Typography>
//           <Typography variant="body1" color="textSecondary" textAlign="center" mb={2}>
//             Upload a CSV file to add multiple students.
//           </Typography>
//           <input
//             type="file"
//             accept=".csv"
//             onChange={handleFileChange}
//             disabled={isLoading}
//             style={{ display: "none" }}
//             id="csv-upload"
//           />
//           <label htmlFor="csv-upload">
//             <MDButton variant="outlined" component="span" color="info" disabled={isLoading}>
//               {isLoading ? "Uploading CSV File..." : "Choose CSV File"}
//             </MDButton>
//           </label>
//           {csvFileSm && (
//             <Typography variant="body2" color="textSecondary" mt={2}>
//               {csvFileSm.name}
//             </Typography>
//           )}
//           <MDButton
//             variant="contained"
//             color="info"
//             onClick={addStudent}
//             disabled={!csvFileSm || isLoading}
//             sx={{ mt: 2 }}
//             startIcon={isLoading && <CircularProgress size={20} />}
//           >
//             {isLoading ? "Uploading..." : "Add Students"}
//           </MDButton>
//         </MDBox>
//       </MDBox>
//       <Footer />
//     </DashboardLayout>
//   );
// };

// export default AddStudents;

// import React, { useState } from "react";
// import { Box, Button, CircularProgress, Tab, Tabs, TextField, Typography } from "@mui/material";
// import MDBox from "components/MDBox";
// import Footer from "examples/Footer";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import { UserService } from "services/service";
// import { handleErrMessage, handleSuccessMessage } from "services/service";
// import MDButton from "components/MDButton";

// const AddStudents = () => {
//   const [csvFileSm, setCsvFileSm] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [tabIndex, setTabIndex] = useState(0);
//   const [singleStudent, setSingleStudent] = useState({
//     user_code: "",
//     email: "",
//     user_name: "",
//     institution_name: "",
//     course_code: "",
//     cohort: "",
//     status: "",
//   });

//   const handleFileChange = (event) => {
//     setCsvFileSm(event.target.files[0]);
//   };

//   const handleTabChange = (event, newValue) => {
//     setTabIndex(newValue);
//   };

//   const handleSingleStudentChange = (event) => {
//     const { name, value } = event.target;
//     setSingleStudent((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const addStudent = async () => {
//     const formData = new FormData();
//     if (csvFileSm) {
//       formData.append("file", csvFileSm);
//     }
//     console.log("FormData student:", ...formData);

//     try {
//       setIsLoading(true);
//       console.warn("Loading...");
//       console.log(formData);
//       const res = await UserService.createUser(formData);
//       console.log("Student updated successfully:", res.data);
//       console.warn("Load Done");
//       handleSuccessMessage(res.data.message);
//       setCsvFileSm(null);
//     } catch (error) {
//       console.warn("Load Error");
//       console.error("An error occurred while updating the Student:", error);
//       handleErrMessage(error.response?.data?.message || "An unexpected error occurred.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const addSingleStudent = async () => {
//     try {
//       setIsLoading(true);
//       console.warn("Loading...");
//       console.log(singleStudent);
//       const res = await UserService.createUser(singleStudent);
//       console.log("Student added successfully:", res.data);
//       console.warn("Load Done");
//       handleSuccessMessage(res.data.message);
//       setSingleStudent({
//         user_code: "",
//         email: "",
//         user_name: "",
//         institution_name: "",
//         course_code: "",
//         cohort: "",
//         status: "",
//       });
//     } catch (error) {
//       console.warn("Load Error");
//       console.error("An error occurred while adding the Student:", error);
//       handleErrMessage(error.response?.data?.message || "An unexpected error occurred.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox
//         pt={2}
//         pb={4}
//         minHeight={"76vh"}
//         display="flex"
//         flexDirection="column"
//         alignItems="center"
//         // justifyContent="center"
//         bgcolor="#f4f6f8"
//       >
//         <MDBox
//           width="100%"
//           maxWidth="500px"
//           p={3}
//           boxShadow={3}
//           borderRadius="10px"
//           bgcolor="white"
//           display="flex"
//           flexDirection="column"
//           alignItems="center"
//         >
//           {/* <Typography variant="h4" gutterBottom>
//             Add Students
//           </Typography> */}
//           <Tabs value={tabIndex} onChange={handleTabChange} aria-label="add students tabs">
//             <Tab label="Bulk Upload" />
//             <Tab label="Single Student" sx={{ ml: 1 }} />
//           </Tabs>
//           {tabIndex === 0 && (
//             <MDBox display="flex" flexDirection="column" alignItems="center" mt={3}>
//               <Typography variant="body1" color="textSecondary" textAlign="center" mb={2}>
//                 Upload a CSV file to add multiple students.
//               </Typography>
//               <input
//                 type="file"
//                 accept=".csv"
//                 onChange={handleFileChange}
//                 disabled={isLoading}
//                 style={{ display: "none" }}
//                 id="csv-upload"
//               />
//               <label htmlFor="csv-upload">
//                 <MDButton variant="outlined" component="span" color="info" disabled={isLoading}>
//                   {isLoading ? "Uploading CSV File..." : "Choose CSV File"}
//                 </MDButton>
//               </label>
//               {csvFileSm && (
//                 <Typography variant="body2" color="textSecondary" mt={2}>
//                   {csvFileSm.name}
//                 </Typography>
//               )}
//               <MDButton
//                 variant="contained"
//                 color="info"
//                 onClick={addStudent}
//                 disabled={!csvFileSm || isLoading}
//                 sx={{ mt: 2 }}
//                 startIcon={isLoading && <CircularProgress size={20} />}
//               >
//                 {isLoading ? "Uploading..." : "Add Students"}
//               </MDButton>
//             </MDBox>
//           )}
//           {tabIndex === 1 && (
//             <MDBox display="flex" flexDirection="column" alignItems="center" mt={3} width="100%">
//               <TextField
//                 label="User Code"
//                 name="user_code"
//                 value={singleStudent.user_code}
//                 onChange={handleSingleStudentChange}
//                 margin="normal"
//                 fullWidth
//               />
//               <TextField
//                 label="Email"
//                 name="email"
//                 value={singleStudent.email}
//                 onChange={handleSingleStudentChange}
//                 margin="normal"
//                 fullWidth
//               />
//               <TextField
//                 label="User Name"
//                 name="user_name"
//                 value={singleStudent.user_name}
//                 onChange={handleSingleStudentChange}
//                 margin="normal"
//                 fullWidth
//               />
//               <TextField
//                 label="Institution Name"
//                 name="institution_name"
//                 value={singleStudent.institution_name}
//                 onChange={handleSingleStudentChange}
//                 margin="normal"
//                 fullWidth
//               />
//               <TextField
//                 label="Course Code"
//                 name="course_code"
//                 value={singleStudent.course_code}
//                 onChange={handleSingleStudentChange}
//                 margin="normal"
//                 fullWidth
//               />
//               <TextField
//                 label="Cohort"
//                 name="cohort"
//                 value={singleStudent.cohort}
//                 onChange={handleSingleStudentChange}
//                 margin="normal"
//                 fullWidth
//               />
//               <TextField
//                 label="Status"
//                 name="status"
//                 value={singleStudent.status}
//                 onChange={handleSingleStudentChange}
//                 margin="normal"
//                 fullWidth
//               />
//               <MDButton
//                 variant="contained"
//                 color="info"
//                 onClick={addSingleStudent}
//                 disabled={isLoading}
//                 sx={{ mt: 2, width: "100%" }}
//                 startIcon={isLoading && <CircularProgress size={20} />}
//               >
//                 {isLoading ? "Adding..." : "Add Student"}
//               </MDButton>
//             </MDBox>
//           )}
//         </MDBox>
//       </MDBox>
//       <Footer />
//     </DashboardLayout>
//   );
// };

// export default AddStudents;

import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { UserService } from "services/service";
import { handleErrMessage, handleSuccessMessage } from "services/service";
import MDButton from "components/MDButton";
import DownloadIcon from "@mui/icons-material/Download";
import bulkCsvFormat from "./bulk-students-format.csv";
import ReloadIcon from "@mui/icons-material/Refresh";

const AddStudents = () => {
  const [csvFileSm, setCsvFileSm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rejectedUser, setRejectedUser] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [singleStudent, setSingleStudent] = useState({
    // user_code: "",
    email: "",
    user_name: "",
    institution_name: "",
    course_code: "",
    cohort: "",
    status: "",
  });
  const [errors, setErrors] = useState({});
  const statusOptions = ["Applied", "Approved", "Pending", "Completed"];

  const handleFileChange = (event) => {
    setCsvFileSm(event.target.files[0]);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSingleStudentChange = (event) => {
    const { name, value } = event.target;
    setSingleStudent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleStatusChange = (event, value) => {
    setSingleStudent((prevState) => ({
      ...prevState,
      status: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    // if (!singleStudent.user_code) newErrors.user_code = "User code is required";
    if (!singleStudent.email) newErrors.email = "Email is required";
    if (!singleStudent.user_name) newErrors.user_name = "User name is required";
    if (!singleStudent.institution_name)
      newErrors.institution_name = "Institution name is required";
    if (!singleStudent.course_code) newErrors.course_code = "Course code is required";
    if (!singleStudent.cohort) newErrors.cohort = "Cohort is required";
    if (!singleStudent.status) newErrors.status = "Status is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addStudent = async () => {
    // if (!validateForm()) return;

    const formData = new FormData();
    if (csvFileSm) {
      formData.append("file", csvFileSm);
    }
    formData.append("opType", "BULK");
    if (csvFileSm) {
      try {
        setRejectedUser("");
        setIsLoading(true);
        const res = await UserService.createUser(formData);
        setRejectedUser(res.data.file || "");
        console.warn("Load Done");
        handleSuccessMessage(res.data.message);
        setCsvFileSm(null);
      } catch (error) {
        console.warn("Load Error");
        console.error("An error occurred while updating the Student:", error);
        handleErrMessage(error.response?.data?.message || "An unexpected error occurred.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const addSingleStudent = async () => {
    if (!validateForm()) return;

    try {
      setIsLoading(true);
      console.warn("Loading...");
      const updatedSingleStudent = { ...singleStudent, opType: "SINGLE" };
      const res = await UserService.createUser(updatedSingleStudent);
      console.warn("Load Done");
      handleSuccessMessage(res.data.message);
      setSingleStudent({
        // user_code: "",
        email: "",
        user_name: "",
        institution_name: "",
        course_code: "",
        cohort: "",
        status: "",
      });
    } catch (error) {
      console.warn("Load Error");
      console.error("An error occurred while adding the Student:", error);
      handleErrMessage(error.response?.data?.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const downloadRejectUser = () => {
    const link = document.createElement("a");
    link.href = rejectedUser;
    link.setAttribute("download", "rejected_users.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement("a");
    link.href = bulkCsvFormat;
    link.setAttribute("download", "bulk-students-format.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleReload = () => {
    console.log("Reloading...");
    setCsvFileSm(null);
    setRejectedUser("");
    location.reload();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        pt={2}
        pb={4}
        minHeight={"76vh"}
        display="flex"
        flexDirection="column"
        alignItems="center"
        bgcolor="#f4f6f8"
      >
        <MDBox
          width="100%"
          maxWidth="500px"
          p={3}
          boxShadow={3}
          borderRadius="10px"
          bgcolor="white"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            border: "2px solid gray",
            "&:hover": {
              border: "2px solid #1662C4",
            },
          }}
          mb={3}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="add students tabs"
            // textColor="inherit"
            // indicatorColor="primary"
          >
            <Tab
              label="Bulk Upload"
              sx={{
                px: 1,
                bgcolor: tabIndex === 0 && "lightskyblue",
                fontWeight: tabIndex === 0 && 500,
              }}
            />
            <Tab
              label="Single Student"
              sx={{
                ml: 1,
                px: 1,
                bgcolor: tabIndex === 1 && "lightskyblue",
                fontWeight: tabIndex === 1 && 500,
              }}
            />
          </Tabs>
          {tabIndex === 0 && (
            <MDBox display="flex" flexDirection="column" alignItems="center" mt={3}>
              <Typography variant="body1" color="textSecondary" textAlign="center" mb={2}>
                Upload a CSV file to add multiple students.
              </Typography>
              <MDBox display="flex" alignItems="center" mb={2} gap={1}>
                <Tooltip title="Download CSV Format">
                  <IconButton
                    disabled={isLoading}
                    sx={{ bgcolor: "lightgrey" }}
                    onClick={handleDownloadTemplate}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Reset">
                  <IconButton
                    disabled={isLoading}
                    sx={{ bgcolor: "lightgrey" }}
                    onClick={handleReload}
                  >
                    <ReloadIcon />
                  </IconButton>
                </Tooltip>
              </MDBox>
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                disabled={isLoading}
                style={{ display: "none" }}
                id="csv-upload"
              />
              <label htmlFor="csv-upload">
                <MDButton variant="outlined" component="span" color="info" disabled={isLoading}>
                  {isLoading ? "Uploading CSV File..." : "Choose CSV File"}
                </MDButton>
              </label>
              {errors.csvFileSm && (
                <Typography variant="body2" color="error" mt={2}>
                  {errors.csvFileSm}
                </Typography>
              )}
              {csvFileSm && (
                <Typography variant="body2" color="textSecondary" mt={2}>
                  {csvFileSm.name}
                </Typography>
              )}
              <Box sx={{ display: "flex" }}>
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={addStudent}
                  disabled={isLoading || !csvFileSm}
                  sx={{ mt: 2 }}
                  startIcon={isLoading && <CircularProgress size={20} />}
                >
                  {isLoading ? "Uploading..." : "Add Students"}
                </MDButton>
                {rejectedUser && (
                  <MDButton
                    variant="contained"
                    color="warning"
                    onClick={downloadRejectUser}
                    sx={{ mt: 2, ml: 2 }}
                    // startIcon={isLoading && <CircularProgress size={20} />}
                  >
                    Download Rejected Users
                  </MDButton>
                )}
              </Box>
            </MDBox>
          )}
          {tabIndex === 1 && (
            <MDBox display="flex" flexDirection="column" alignItems="center" mt={3} width="100%">
              {/* <TextField
                label="User Code"
                name="user_code"
                value={singleStudent.user_code}
                onChange={handleSingleStudentChange}
                margin="normal"
                type="number"
                fullWidth
                error={!!errors.user_code}
                helperText={errors.user_code}
              /> */}
              <TextField
                label="Email"
                name="email"
                type="email"
                value={singleStudent.email}
                onChange={handleSingleStudentChange}
                margin="normal"
                fullWidth
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                label="User Name"
                name="user_name"
                value={singleStudent.user_name}
                onChange={handleSingleStudentChange}
                margin="normal"
                fullWidth
                error={!!errors.user_name}
                helperText={errors.user_name}
              />
              <TextField
                label="Institution Name"
                name="institution_name"
                value={singleStudent.institution_name}
                onChange={handleSingleStudentChange}
                margin="normal"
                fullWidth
                error={!!errors.institution_name}
                helperText={errors.institution_name}
              />
              <TextField
                label="Course Code"
                name="course_code"
                value={singleStudent.course_code}
                onChange={handleSingleStudentChange}
                margin="normal"
                fullWidth
                type="number"
                error={!!errors.course_code}
                helperText={errors.course_code}
              />
              <TextField
                label="Cohort"
                name="cohort"
                value={singleStudent.cohort}
                onChange={handleSingleStudentChange}
                margin="normal"
                type="number"
                fullWidth
                error={!!errors.cohort}
                helperText={errors.cohort}
              />
              {/* <TextField
                label="Status"
                name="status"
                value={singleStudent.status}
                onChange={handleSingleStudentChange}
                margin="normal"
                fullWidth
                error={!!errors.status}
                helperText={errors.status}
              /> */}
              <FormControl fullWidth margin="normal" error={!!errors.status}>
                <Autocomplete
                  options={statusOptions}
                  value={singleStudent.status}
                  onChange={handleStatusChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      size="medium"
                      error={!!errors.status}
                      helperText={errors.status}
                      InputProps={{
                        ...params.InputProps,
                        sx: { height: 46 },
                      }}
                    />
                  )}
                />
                {/* {errors.status && (
                  <Typography variant="body2" color="error">
                    {errors.status}
                  </Typography>
                )} */}
              </FormControl>

              <MDButton
                variant="contained"
                color="info"
                onClick={addSingleStudent}
                disabled={isLoading}
                sx={{ mt: 2, width: "100%" }}
                startIcon={isLoading && <CircularProgress size={20} />}
              >
                {isLoading ? "Adding..." : "Add Student"}
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AddStudents;
