// Santanu Garnaik Design

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Santanu Garnaik Design React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Santanu Garnaik Design React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Santanu Garnaik Design React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: true,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  const newValue = [{ ...value[0], whiteSidenav: true }, value[1]];

  return <MaterialUI.Provider value={newValue}>{children}</MaterialUI.Provider>;
}

// Santanu Garnaik Design React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
};

// import { createContext, useContext, useReducer, useMemo } from "react";
// import PropTypes from "prop-types";

// const MaterialUI = createContext();
// MaterialUI.displayName = "MaterialUIContext";

// function reducer(state, action) {
//   switch (action.type) {
//     case "MINI_SIDENAV":
//       return { ...state, miniSidenav: action.value };
//     case "TRANSPARENT_SIDENAV":
//       return { ...state, transparentSidenav: action.value };
//     case "WHITE_SIDENAV":
//       return { ...state, whiteSidenav: action.value };
//     case "SIDENAV_COLOR":
//       return { ...state, sidenavColor: action.value };
//     case "TRANSPARENT_NAVBAR":
//       return { ...state, transparentNavbar: action.value };
//     case "FIXED_NAVBAR":
//       return { ...state, fixedNavbar: action.value };
//     case "OPEN_CONFIGURATOR":
//       return { ...state, openConfigurator: action.value };
//     case "DIRECTION":
//       return { ...state, direction: action.value };
//     case "LAYOUT":
//       return { ...state, layout: action.value };
//     case "DARKMODE":
//       return { ...state, darkMode: action.value };
//     case "SET_SELECTED_MODULE_CODE":
//       return { ...state, selectedModuleCode: action.value };
//     case "SET_SELECTED_MODULE_DATA":
//       return { ...state, selectedModuleData: action.value };
//     default:
//       throw new Error(`Unhandled action type: ${action.type}`);
//   }
// }

// function MaterialUIControllerProvider({ children }) {
//   const initialState = {
//     miniSidenav: false,
//     transparentSidenav: false,
//     whiteSidenav: false,
//     sidenavColor: "info",
//     transparentNavbar: true,
//     fixedNavbar: true,
//     openConfigurator: false,
//     direction: "ltr",
//     layout: "dashboard",
//     darkMode: false,
//     selectedModuleCode: null,
//     selectedModuleData: null,
//   };

//   const [controller, dispatch] = useReducer(reducer, initialState);
//   const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

//   return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
// }

// function useMaterialUIController() {
//   const context = useContext(MaterialUI);

//   if (!context) {
//     throw new Error(
//       "useMaterialUIController should be used inside the MaterialUIControllerProvider."
//     );
//   }

//   return context;
// }

// MaterialUIControllerProvider.propTypes = {
//   children: PropTypes.node.isRequired,
// };

// const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
// const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
// const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
// const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
// const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
// const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
// const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
// const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
// const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
// const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
// const setSelectedModuleCode = (dispatch, value) =>
//   dispatch({ type: "SET_SELECTED_MODULE_CODE", value });
// const setSelectedModuleData = (dispatch, value) =>
//   dispatch({ type: "SET_SELECTED_MODULE_DATA", value });

// export {
//   MaterialUIControllerProvider,
//   useMaterialUIController,
//   setMiniSidenav,
//   setTransparentSidenav,
//   setWhiteSidenav,
//   setSidenavColor,
//   setTransparentNavbar,
//   setFixedNavbar,
//   setOpenConfigurator,
//   setDirection,
//   setLayout,
//   setDarkMode,
//   setSelectedModuleCode,
//   setSelectedModuleData,
// };

// import { createContext, useContext, useReducer, useMemo } from "react";
// import PropTypes from "prop-types";

// const MaterialUI = createContext();
// MaterialUI.displayName = "MaterialUIContext";

// function reducer(state, action) {
//   switch (action.type) {
//     case "MINI_SIDENAV":
//       return { ...state, miniSidenav: action.value };
//     case "TRANSPARENT_SIDENAV":
//       return { ...state, transparentSidenav: action.value };
//     case "WHITE_SIDENAV":
//       return { ...state, whiteSidenav: action.value };
//     case "SIDENAV_COLOR":
//       return { ...state, sidenavColor: action.value };
//     case "TRANSPARENT_NAVBAR":
//       return { ...state, transparentNavbar: action.value };
//     case "FIXED_NAVBAR":
//       return { ...state, fixedNavbar: action.value };
//     case "OPEN_CONFIGURATOR":
//       return { ...state, openConfigurator: action.value };
//     case "DIRECTION":
//       return { ...state, direction: action.value };
//     case "LAYOUT":
//       return { ...state, layout: action.value };
//     case "DARKMODE":
//       return { ...state, darkMode: action.value };
//     case "SET_SELECTED_MODULE_CODE":
//       return { ...state, selectedModuleCode: action.value };
//     case "SET_SELECTED_MODULE_DATA":
//       return { ...state, selectedModuleData: action.value };
//     default:
//       throw new Error(`Unhandled action type: ${action.type}`);
//   }
// }

// function MaterialUIControllerProvider({ children }) {
//   const initialState = {
//     miniSidenav: false,
//     transparentSidenav: false,
//     whiteSidenav: false,
//     sidenavColor: "info",
//     transparentNavbar: true,
//     fixedNavbar: true,
//     openConfigurator: false,
//     direction: "ltr",
//     layout: "dashboard",
//     darkMode: false,
//     selectedModuleCode: null,
//     selectedModuleData: null,
//   };

//   const [controller, dispatch] = useReducer(reducer, initialState);
//   const value = useMemo(() => ({ controller, dispatch }), [controller, dispatch]);

//   return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
// }

// function useMaterialUIController() {
//   const context = useContext(MaterialUI);

//   if (!context) {
//     throw new Error(
//       "useMaterialUIController should be used inside the MaterialUIControllerProvider."
//     );
//   }

//   return context;
// }

// MaterialUIControllerProvider.propTypes = {
//   children: PropTypes.node.isRequired,
// };

// const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
// const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
// const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
// const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
// const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
// const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
// const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
// const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
// const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
// const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
// const setSelectedModuleCode = (dispatch, value) =>
//   dispatch({ type: "SET_SELECTED_MODULE_CODE", value });
// const setSelectedModuleData = (dispatch, value) =>
//   dispatch({ type: "SET_SELECTED_MODULE_DATA", value });

// export {
//   MaterialUIControllerProvider,
//   useMaterialUIController,
//   setMiniSidenav,
//   setTransparentSidenav,
//   setWhiteSidenav,
//   setSidenavColor,
//   setTransparentNavbar,
//   setFixedNavbar,
//   setOpenConfigurator,
//   setDirection,
//   setLayout,
//   setDarkMode,
//   setSelectedModuleCode,
//   setSelectedModuleData,
// };
